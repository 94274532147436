import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  SearchMap,
  ModalInMobile,
  Page,
  SearchFiltersPrimary,
  SortBy,
  InlineTextButton,
  LayoutWrapperTopbar,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import { searchMapListings, setActiveListing } from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import css from './SearchPage.module.css';
import { isAnyFilterActive } from '../../util/search';
import FilterComponent from './FilterComponent';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';
import LoaderComponent from '../../components/LoaderComponent/LoaderComponent';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.
const FILTER_DROPDOWN_OFFSET = -14;

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      currentQueryParams: {},
      category: [],
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named certificate
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  initialValues(queryParamNames) {
    // Query parameters that are visible in the URL
    const { filterConfig, sortConfig, searchParams, location } = this.props;
    const { mapSearch, page, ...searchInURL } = parse(location?.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);
    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = (paramName) => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState ? currentQueryParam : validQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
    // return {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    const { filterConfig, sortConfig, searchParams, history, location } = this.props;
    const { mapSearch, page, ...searchInURL } = parse(location?.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    return (updatedURLParams) => {
      const updater = (prevState) => {
        const { address, bounds } = urlQueryParams;
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        return {
          currentQueryParams: { ...mergedQueryParams, ...updatedURLParams, address, bounds },
        };
      };

      const callback = () => {
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
          history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, urlQueryParams } = this.props;
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  fun = () => {
    const { history, urlQueryParams, sortConfig, filterConfig } = this.props;
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    let search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);
    search = { ...search, pub_subcategory: null };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  };

  componentDidMount() {
    const { history } = this.props;
    axios.get(`${apiBaseUrl()}/api/category`).then((resp) => {
      if (resp.data) {
        //key: 'yoga',
        //     label: 'Yoga',
        //     subCategory: [
        //       { key: 'vinyasaYoga', label: 'Vinyasa Yoga' },
        //       { key: 'hotYoga', label: 'Hot Yoga' },
        //     ]
        this.setState({
          category: resp.data.map(({ name, subCat, key }) => ({
            key: key,
            label: name,
            subCategory: subCat.map(({ name, key }) => ({ label: name, key })),
          })),
        });
      }
    });

    if (
      typeof window !== 'undefined' &&
      window.location.search === '?pub_activity=has_any%3AfacilityHire'
    ) {
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, {}));
    }
  }

  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      history,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      currentUser,
    } = this.props;
    // console.log(909, history);
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location?.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    const primaryFilters = filterConfig.filter((f) => f.group === 'primary');
    primaryFilters.forEach((obj) => {
      obj.config.options = obj.config.options.filter((option) => option.key !== 'facilityHire');
    });

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;

    const sortBy = (mode) => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        urlQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
              rootClassName: css.sortBy,
              menuLabelRootClassName: css.sortByMenuLabel,
            }
          : {};
      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={urlQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;

    const hasNoResult = listingsAreLoaded && totalItems === 0;

    const searchParamsForPagination = parse(location.search);
    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer
              className={topbarClasses}
              currentPage="SearchPage"
              currentSearchParams={urlQueryParams}
            />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {searchInProgress && !listingsAreLoaded ? (
              <LoaderComponent className={css.loaderContainer} />
            ) : (
              <>
                <div className={css.searchFilterSection}>
                  <SearchFiltersPrimary
                    className={css.searchFiltersPrimary}
                    sortByComponent={sortBy('desktop')}
                    listingsAreLoaded={listingsAreLoaded}
                    resultsCount={totalItems}
                    searchInProgress={searchInProgress}
                    searchListingsError={searchListingsError}
                    // {...propsForSecondaryFiltersToggle}
                  >
                    {primaryFilters.map((config) => {
                      // fetching category list and assigning subcategory options from the selected category
                      const categoryList = primaryFilters.find((val) => val.id === 'category')
                        ?.config.options;
                      const selectedCategoryOption = this.state.category.find(
                        (o) => o.key === searchParamsForPagination.pub_category
                      )?.subCategory;

                      //  destruturing and overriding the options with subcategory

                      config =
                        config.id === 'category'
                          ? {
                              ...config,
                              config: {
                                searchMode: 'has_any',
                                options: this.state.category ?? [],
                              },
                            }
                          : config.id === 'subCategory'
                          ? {
                              ...config,
                              config: {
                                searchMode: 'has_any',
                                options: selectedCategoryOption ?? [],
                              },
                            }
                          : config;

                      !searchParamsForPagination.pub_category &&
                      searchParamsForPagination.pub_subCategory
                        ? this.fun()
                        : '';

                      return (
                        <FilterComponent
                          key={`SearchFiltersPrimary.${config.id}`}
                          idPrefix="SearchFiltersPrimary"
                          filterConfig={config}
                          urlQueryParams={urlQueryParams}
                          initialValues={this.initialValues}
                          getHandleChangedValueFn={this.getHandleChangedValueFn}
                          showAsPopup
                          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                          // rootClassName={css.filterLabel}
                          className={css.filterLabel}
                        />
                      );
                    })}
                    {/* <InlineTextButton
                className={css.clearFilter}
                onClick={() => {
                  this.setState({ currentQueryParams: {} }, () =>
                    history.replace(
                      createResourceLocatorString('SearchPage', routeConfiguration(), {}, {})
                    )
                  );
                }}
              >
                Clear filter
              </InlineTextButton> */}
                  </SearchFiltersPrimary>
                  <InlineTextButton
                    className={css.clearFilter}
                    onClick={() => {
                      this.setState({ currentQueryParams: {} }, () =>
                        history.replace(
                          createResourceLocatorString('SearchPage', routeConfiguration(), {}, {})
                        )
                      );
                    }}
                  >
                    Clear filter
                  </InlineTextButton>
                </div>

                <div className={css.container}>
                  {/* {hasNoResult ? (
                <div className={css.noSearchResults}>
                  <FormattedMessage id="SearchFiltersPrimary.noResults" />
                </div>
              ) : null} */}
                  <MainPanel
                    hasNoResult={hasNoResult}
                    urlQueryParams={validQueryParams}
                    listings={listings}
                    searchInProgress={searchInProgress}
                    searchListingsError={searchListingsError}
                    searchParamsAreInSync={searchParamsAreInSync}
                    onActivateListing={onActivateListing}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onOpenModal={this.onOpenMobileModal}
                    onCloseModal={this.onCloseMobileModal}
                    onMapIconClick={onMapIconClick}
                    pagination={pagination}
                    searchParamsForPagination={searchParamsForPagination}
                    showAsModalMaxWidth={MODAL_BREAKPOINT}
                    history={history}
                    currentUser={currentUser}
                    location={location}
                    category={this.state.category}
                  />

                  <ModalInMobile
                    className={css.mapPanel}
                    id="SearchPage.map"
                    isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                    onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
                    showAsModalMaxWidth={MODAL_BREAKPOINT}
                    onManageDisableScrolling={onManageDisableScrolling}
                  >
                    <div className={css.mapWrapper}>
                      {shouldShowSearchMap ? (
                        <SearchMap
                          reusableContainerClassName={css.map}
                          activeListingId={activeListingId}
                          bounds={bounds}
                          center={origin}
                          isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                          location={location}
                          listings={mapListings || []}
                          onMapMoveEnd={this.onMapMoveEnd}
                          onCloseAsModal={() => {
                            onManageDisableScrolling('SearchPage.map', false);
                          }}
                          messages={intl.messages}
                        />
                      ) : null}
                    </div>
                  </ModalInMobile>
                </div>
              </>
            )}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = (state) => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.SearchPage;
  const { currentUser } = state.user;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    currentUser,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: (searchParams) => dispatch(searchMapListings(searchParams)),
  onActivateListing: (listingId) => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
